import React, { useContext, useEffect, useState } from "react";
import { cacheBuster } from "./config";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import { DropdownContext } from "./DropdownContext";

function NewsChannels() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dropdownContext = useContext(DropdownContext);

  if (!dropdownContext) {
    throw new Error("DropdownContext must be used within DropdownProvider");
  }

  const { selectedOption } = dropdownContext;

  // Add new logo name variable here at the end after "aljazeera", For eg: "newlogoname",
  const [tabs] = useState([
    "skynewslive",
    "euronews",
    "france",
    "bloombergbusinessnews",
    "abcnews",
    "dwnews",
    "usatoday",
    "cnbc",
    "indiatoday",
    "wion",
    "channelnewsasia",
    "newsmax",
    "arirang",
    "ndtv",
    "abcnewsaustralia",
    "aljazeera",
  ]);

  const [selectedEnglishTab, setSelectedEnglishTab] = useState<string>(
    () => localStorage.getItem("selectedEnglishTab") || "skynewslive"
  );

  console.log(selectedOption);
  // Handle tab selection
  const handleTabClick = (channelKey: string) => {
    setSelectedEnglishTab(channelKey);
    localStorage.setItem("selectedEnglishTab", channelKey);
  };

  useEffect(() => {
    const savedTab = localStorage.getItem("selectedEnglishTab");
    if (savedTab) {
      setSelectedEnglishTab(savedTab);
    }
  }, []);

  const [visibleTabs, setVisibleTabs] = useState(tabs);
  // Add channel name in "" which you want to hide with comma separated. For eg: const hiddenTabs: any = ["skynewslive", "euronews"];
  const hiddenTabs: any = [""];

  useEffect(() => {
    const updatedVisibleTabs = visibleTabs.filter(
      (tab) => !hiddenTabs.includes(tab)
    );
    setVisibleTabs(updatedVisibleTabs);

    if (hiddenTabs.includes(selectedEnglishTab)) {
      const nextVisibleTab = updatedVisibleTabs[0];
      if (nextVisibleTab) {
        setSelectedEnglishTab(nextVisibleTab);
      }
    }
  }, []);

  /*****  To add new logo add this line with new logo name start ******/
  const skynewslive = `/assets/channel-logos/sky-news-live.jpg?v=${cacheBuster}`;
  /*****  To add new logo add this line with new logo name end ******/
  const euronews = `/assets/channel-logos/euronews.png?v=${cacheBuster}`;
  const france = `/assets/channel-logos/france.png?v=${cacheBuster}`;
  const bloombergbusinessnews = `/assets/channel-logos/bloomberg-business-news.jpg?v=${cacheBuster}`;
  const abcnews = `/assets/channel-logos/abc-news.png?v=${cacheBuster}`;
  const dwnews = `/assets/channel-logos/dwnews.png?v=${cacheBuster}`;
  const usatoday = `/assets/channel-logos/usa-today.jpg?v=${cacheBuster}`;
  const cnbc = `/assets/channel-logos/cnbc.png?v=${cacheBuster}`;
  const indiatoday = `/assets/channel-logos/india-today.png?v=${cacheBuster}`;
  const wion = `/assets/channel-logos/wion.png?v=${cacheBuster}`;
  const channelnewsasia = `/assets/channel-logos/channel-news-asia.jpg?v=${cacheBuster}`;
  const newsmax = `/assets/channel-logos/newsmax.png?v=${cacheBuster}`;
  const arirang = `/assets/channel-logos/arirang.jpg?v=${cacheBuster}`;
  const ndtv = `/assets/channel-logos/ndtv.png?v=${cacheBuster}`;
  const abcnewsaustralia = `/assets/channel-logos/abc-news-australia.png?v=${cacheBuster}`;
  const aljazeera = `/assets/channel-logos/al-jazeera.png?v=${cacheBuster}`;

  const imagesToPreload = [
    skynewslive,
    euronews,
    france,
    bloombergbusinessnews,
    abcnews,
    dwnews,
    usatoday,
    cnbc,
    indiatoday,
    wion,
    channelnewsasia,
    newsmax,
    arirang,
    ndtv,
    abcnewsaustralia,
    aljazeera,
  ];

  imagesToPreload.forEach((src) => {
    const img = new Image();
    img.src = src;
  });

  return (
    <>
      <div className="Apps">
        <Helmet>
          <title>Live English News | Watch Top Global Channels Online</title>
          <meta
            name="description"
            content="Watch live English news from top channels like Sky News, Euronews, Bloomberg, ABC News & more. Stay updated on global politics, finance, and culture in real-time."
          />
          <meta
            name="keywords"
            content="Live English news, watch news live, global news channels, Sky News live, Euronews live, France 24 live, Bloomberg live, ABC News live, DW News live, USA Today live, India Today live, WION live, Channel NewsAsia live, Newsmax TV live, NDTV live, Al Jazeera live, English news streaming"
          />
        </Helmet>
        <Header />

        {selectedOption === "English" && (
          <>
            <div className="logo-grid">
              <div className="tabs">
                {/******  To add new logo copy paste this div(from line number 141 to 160) and change channel name wherever necessary and change the order start *******/}
                {visibleTabs.includes("skynewslive") && (
                  <div
                    className={`tab-item ${
                      selectedEnglishTab === "skynewslive" ? "active" : ""
                    } order-1`}
                    onClick={() => handleTabClick("skynewslive")}
                  >
                    {/**** When you add new logo change the src={skynewslive} to src={newlogoname} in below div start ****/}
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={skynewslive}
                        alt="Sky News Live"
                      />
                    </div>
                    {/**** When you add new logo change the src={skynewslive} to src={newlogoname} in below div end ****/}
                  </div>
                )}
                {/******  To add new logo copy paste this div and change channel name wherever necessary and change the order end *******/}

                {visibleTabs.includes("euronews") && (
                  <div
                    className={`tab-item ${
                      selectedEnglishTab === "euronews" ? "active" : ""
                    } order-2`}
                    onClick={() => handleTabClick("euronews")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={euronews}
                        alt="Euronews Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("france") && (
                  <div
                    className={`tab-item ${
                      selectedEnglishTab === "france" ? "active" : ""
                    } order-3`}
                    onClick={() => handleTabClick("france")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={france}
                        alt="France 24 Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("bloombergbusinessnews") && (
                  <div
                    className={`tab-item ${
                      selectedEnglishTab === "bloombergbusinessnews"
                        ? "active"
                        : ""
                    } order-4`}
                    onClick={() => handleTabClick("bloombergbusinessnews")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={bloombergbusinessnews}
                        alt="Bloomberg Business News Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("abcnews") && (
                  <div
                    className={`tab-item ${
                      selectedEnglishTab === "abcnews" ? "active" : ""
                    } order-5`}
                    onClick={() => handleTabClick("abcnews")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={abcnews}
                        alt="ABC News Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("dwnews") && (
                  <div
                    className={`tab-item ${
                      selectedEnglishTab === "dwnews" ? "active" : ""
                    } order-6`}
                    onClick={() => handleTabClick("dwnews")}
                  >
                    <div className="image-container">
                      <img
                        rel="preload"
                        height="80"
                        width="80"
                        src={dwnews}
                        alt="DW News Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("usatoday") && (
                  <div
                    className={`tab-item ${
                      selectedEnglishTab === "usatoday" ? "active" : ""
                    } order-7`}
                    onClick={() => handleTabClick("usatoday")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={usatoday}
                        alt="USA TODAY Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("cnbc") && (
                  <div
                    className={`tab-item ${
                      selectedEnglishTab === "cnbc" ? "active" : ""
                    } order-8`}
                    onClick={() => handleTabClick("cnbc")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={cnbc}
                        alt="CNBC Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("indiatoday") && (
                  <div
                    className={`tab-item ${
                      selectedEnglishTab === "indiatoday" ? "active" : ""
                    } order-9`}
                    onClick={() => handleTabClick("indiatoday")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={indiatoday}
                        alt="India Today Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("wion") && (
                  <div
                    className={`tab-item ${
                      selectedEnglishTab === "wion" ? "active" : ""
                    } order-10`}
                    onClick={() => handleTabClick("wion")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={wion}
                        alt="WION Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("channelnewsasia") && (
                  <div
                    className={`tab-item ${
                      selectedEnglishTab === "channelnewsasia" ? "active" : ""
                    } order-11`}
                    onClick={() => handleTabClick("channelnewsasia")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={channelnewsasia}
                        alt="Channel NewsAsia (CNA) Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("newsmax") && (
                  <div
                    className={`tab-item ${
                      selectedEnglishTab === "newsmax" ? "active" : ""
                    } order-12`}
                    onClick={() => handleTabClick("newsmax")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={newsmax}
                        alt="Newsmax TV Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("arirang") && (
                  <div
                    className={`tab-item ${
                      selectedEnglishTab === "arirang" ? "active" : ""
                    } order-13`}
                    onClick={() => handleTabClick("arirang")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={arirang}
                        alt="Arirang News Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("ndtv") && (
                  <div
                    className={`tab-item ${
                      selectedEnglishTab === "ndtv" ? "active" : ""
                    } order-14`}
                    onClick={() => handleTabClick("ndtv")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={ndtv}
                        alt="NDTV 24x7 Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("abcnewsaustralia") && (
                  <div
                    className={`tab-item ${
                      selectedEnglishTab === "abcnewsaustralia" ? "active" : ""
                    } order-15`}
                    onClick={() => handleTabClick("abcnewsaustralia")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={abcnewsaustralia}
                        alt="ABC News Australia live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("aljazeera") && (
                  <div
                    className={`tab-item ${
                      selectedEnglishTab === "aljazeera" ? "active" : ""
                    } order-16`}
                    onClick={() => handleTabClick("aljazeera")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={aljazeera}
                        alt="Al Jazeera Live"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Display the selected YouTube video  */}

            <div className="video-container">
              {/******  To add new youtube video iframe copy paste this part and change channel name wherever necessary start  *******/}

              {selectedEnglishTab === "skynewslive" &&
                visibleTabs.includes("skynewslive") && (
                  <>
                    <h1>Sky News Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/oJUvTVdTMyY?si=_tgAiKAnbf_iLZIh&autoplay=1&v=${cacheBuster}`}
                      title="Sky News Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {/******  To add new youtube video iframe copy paste this part and change channel name wherever necessary end *******/}

              {selectedEnglishTab === "euronews" &&
                visibleTabs.includes("euronews") && (
                  <>
                    <h1>Euronews Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/pykpO5kQJ98?si=adJpes_jtMEyFdZy&autoplay=1&v=${cacheBuster}`}
                      title="Euronews Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedEnglishTab === "france" &&
                visibleTabs.includes("france") && (
                  <>
                    <h1>France 24 Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/Ap-UM1O9RBU?si=lvbA-C0uwj6Ee1Zx&autoplay=1&v=${cacheBuster}`}
                      title="France 24 Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedEnglishTab === "bloombergbusinessnews" &&
                visibleTabs.includes("bloombergbusinessnews") && (
                  <>
                    <h1>Bloomberg Business News Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/iEpJwprxDdk?si=y0zOrfacHY913Kyz&autoplay=1&v=${cacheBuster}`}
                      title="Bloomberg Business News Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedEnglishTab === "abcnews" &&
                visibleTabs.includes("abcnews") && (
                  <>
                    <h1>ABC News Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/-mvUkiILTqI?si=cV8Bdwsvay7_TZd1&autoplay=1&v=${cacheBuster}`}
                      title="ABC News Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedEnglishTab === "dwnews" &&
                visibleTabs.includes("dwnews") && (
                  <>
                    <h1>DW News Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/tZT2MCYu6Zw?si=NRfcDXpCd3ShjqEf&autoplay=1&v=${cacheBuster}`}
                      title="DW News Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedEnglishTab === "usatoday" &&
                visibleTabs.includes("usatoday") && (
                  <>
                    <h1>USA TODAY Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/vZYMwAm8sso?si=08OqgmEcXlaL3CqE&autoplay=1&v=${cacheBuster}`}
                      title="USA TODAY Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedEnglishTab === "cnbc" &&
                visibleTabs.includes("cnbc") && (
                  <>
                    <h1>CNBC Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/9NyxcX3rhQs?si=wlXki_6MZBaGP1ms&autoplay=1&v=${cacheBuster}`}
                      title="CNBC Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedEnglishTab === "indiatoday" &&
                visibleTabs.includes("indiatoday") && (
                  <>
                    <h1>India Today Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/sYZtOFzM78M?si=uu65ZhdCxU19U43g&autoplay=1&v=${cacheBuster}`}
                      title="India Today Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedEnglishTab === "wion" &&
                visibleTabs.includes("wion") && (
                  <>
                    <h1>WION Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/gadjsB5BkK4?si=VRoplWf3fN73JHov&autoplay=1&v=${cacheBuster}`}
                      title="WION Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedEnglishTab === "channelnewsasia" &&
                visibleTabs.includes("channelnewsasia") && (
                  <>
                    <h1>Channel NewsAsia (CNA) Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/XWq5kBlakcQ?si=CJNJT8rSAstMN4_-&autoplay=1&v=${cacheBuster}`}
                      title="Channel NewsAsia (CNA) Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedEnglishTab === "newsmax" &&
                visibleTabs.includes("newsmax") && (
                  <>
                    <h1>Newsmax TV Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/zognWzoTMT4?si=ET6PnlsEzdv6s95g&autoplay=1&v=${cacheBuster}`}
                      title="Newsmax TV Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedEnglishTab === "arirang" &&
                visibleTabs.includes("arirang") && (
                  <>
                    <h1>Arirang News Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/CJVBX7KI5nU?si=WChOnRIpUT0tZzpE&autoplay=1&v=${cacheBuster}`}
                      title="Arirang News Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedEnglishTab === "ndtv" &&
                visibleTabs.includes("ndtv") && (
                  <>
                    <h1>NDTV 24x7 Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/RQrLqUxw0U0?si=g0O4k2iG8APq8OtU&autoplay=1&v=${cacheBuster}`}
                      title="NDTV 24x7 Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedEnglishTab === "abcnewsaustralia" &&
                visibleTabs.includes("abcnewsaustralia") && (
                  <>
                    <h1>ABC News Australia live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/vOTiJkg1voo?si=fkzvLH9B3Rg_C852&autoplay=1&v=${cacheBuster}`}
                      title="ABC News Australia live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedEnglishTab === "aljazeera" &&
                visibleTabs.includes("aljazeera") && (
                  <>
                    <h1>Al Jazeera Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/gCNeDWCI0vo?si=0DDyjTO1M00E2_jS&autoplay=1&v=${cacheBuster}`}
                      title="Al Jazeera Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}
            </div>
          </>
        )}

        <p style={{ display: "none" }}>
          Welcome to English News Live, your source for the top English news
          channels from around the world, streaming live in one place. Stay
          updated on current affairs, business news, global events, and breaking
          news with channels like Sky News, Euronews, France 24, Bloomberg, ABC
          News, and more. Whether you’re interested in U.S., European, Asian, or
          Indian news, we bring you reliable and up-to-the-minute live
          broadcasts from trusted news networks.
        </p>
      </div>
      <Footer />
    </>
  );
}

export default NewsChannels;
